import $ from 'jquery'
import 'select2'

const initSelect2 = (dropdownParent) => {
  $('.select2').select2({
    dropdownParent: dropdownParent || $(document.body),
    theme: 'bootstrap-5',
    escapeMarkup: (element) => element
  })
}

const initSelect2Modals = () => {
  initSelect2($('#modalWindow'))
}

document.addEventListener('DOMContentLoaded', () => {
  initSelect2()

  document.body.addEventListener('show.bs.modal', initSelect2Modals)
})

$(document)
  .on('select2:open', () => {
    document.querySelector('.select2-search__field').focus()
  })
  .on('ajax-modal-show', initSelect2Modals)
