import $ from 'jquery';
import Rails from "@rails/ujs";

document.addEventListener('DOMContentLoaded', () => {
  $(document).on('change', '.check-all-items-checkboxes', function () {
    $('.row-checkbox').prop('checked', $(this).prop('checked'));
    if ($('.row-checkbox:checked').length > 0) {
      Rails.fire($(this).closest('form')[0], 'submit');
    }
  });

  $(document).on('click', '.row-checkbox', function () {
    Rails.fire($(this).closest('form')[0], 'submit');
    $(this).replaceWith($('<i class="fa fa-spin fa-spinner"></i>'));
  })
});