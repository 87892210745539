const headerHeight = !!document.querySelector('#main-header') ? document.querySelector('#main-header').offsetHeight + 16 : 16;

const scrollIntoViewWithOffset = (element, offset) => {
  window.scrollTo({
    behavior: 'smooth',
    top:
      element.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      headerHeight + offset,
  })
}

document.body.addEventListener('click', (event) => {
  const path = event.composedPath();
  const element = path.find(element => element.matches && element.matches('[data-toggle="goto-section"]'));

  if (element) {
    const targetSection = document.querySelector(element.getAttribute('href'));
    scrollIntoViewWithOffset(targetSection, parseInt(element.dataset.offsetTop || 0));
    event.preventDefault()
    event.stopPropagation()
  }
});