import { Modal } from 'bootstrap';

const PROMT_MS_BEFORE_TIMEOUT = 300 + 5;

class SessionTimeoutChecker {
  constructor(element) {
    this.sessionKey = element.dataset.sessionKey;
    this.sessionTimeoutInSeconds = parseInt(element.dataset.timeoutIn);
    this.pingPath = element.dataset.pingPath;

    this.aboutToTimeoutPrompt = element.querySelector('#session-about-to-timeout-prompt');
    this.timedOutPrompt = element.querySelector('#session-timed-out-prompt');

    this.aboutToTimeoutModal = Modal.getOrCreateInstance(this.aboutToTimeoutPrompt);
    this.timedOutModal = Modal.getOrCreateInstance(this.timedOutPrompt);

    this.lastPinged = this.currentTime();
    this.promptingSessionTimeout = false;

    this.setTimeoutAt();
    this.addListeners();
  }

  start() {
    this.tick();
    this.tickInterval = setInterval(this.tick, 1000);
  }

  stop() {
    clearInterval(this.tickInterval);
  }

  restart = () => {
    this.stop();
    this.setTimeoutAt();
    this.start();
  }

  tick = () => {
    const timeLeftInSeconds = this.timeoutAt - this.currentTime();
    if (timeLeftInSeconds <= 0) {
      this.showTimeoutPrompt();
    } else if (timeLeftInSeconds <= PROMT_MS_BEFORE_TIMEOUT) {
      this.showAboutToTimeoutPrompt(timeLeftInSeconds);
    }
  }

  currentTime() {
    return Math.floor(Date.now() / 1000);
  }

  setTimeoutAt() {
    this.timeoutAt = this.currentTime() + this.sessionTimeoutInSeconds;
    localStorage.setItem(this.sessionKey, this.timeoutAt);
  }

  addListeners() {
    this.aboutToTimeoutPrompt.querySelector('#session-timeout-prompt-yes-btn').addEventListener('click', () => {
      this.promptingSessionTimeout = false;
      this.aboutToTimeoutModal.hide();
      this.stop();
      this.pingServer();
    });

    addEventListener('storage', (event) => {
      if (event.key === this.sessionKey) {
        this.hideAllPrompts();
        this.stop();
        this.timeoutAt = event.newValue;
        this.start();
      }
    })
  }

  showAboutToTimeoutPrompt(timeLeftInSeconds) {
    if (!this.promptingSessionTimeout) {
      this.promptingSessionTimeout = true;
      this.aboutToTimeoutModal.show();
    }

    const minutesForDisplay = Math.floor(timeLeftInSeconds / 60);
    const secondsForDisplay = Math.floor(timeLeftInSeconds - (minutesForDisplay * 60));

    this.aboutToTimeoutPrompt.querySelector('#session-about-to-timeout-in').innerHTML = `${minutesForDisplay}m ${secondsForDisplay}s`;
  }

  showTimeoutPrompt() {
    this.aboutToTimeoutModal.hide();
    this.timedOutModal.show();
    this.stop();
  }

  hideAllPrompts() {
    this.promptingSessionTimeout = false;
    this.aboutToTimeoutModal.hide();
    this.timedOutModal.hide();
  }

  async pingServer() {
    this.lastPinged = this.currentTime();
    await fetch(this.pingPath);
  }

  pingServerWithInterval = () => {
    if ((!this.promptingSessionTimeout) && ((this.currentTime() - this.lastPinged) > 10))
      this.pingServer();
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.body.dataset.timeoutIn) {
    const timeoutChecker = new SessionTimeoutChecker(document.body);
    timeoutChecker.start();

    addEventListener('scroll', timeoutChecker.pingServerWithInterval);
    document.addEventListener('keydown', timeoutChecker.pingServerWithInterval);
    document.addEventListener('click', timeoutChecker.pingServerWithInterval);

    document.body.addEventListener('ajax:complete', timeoutChecker.restart);
    window.fetch = new Proxy(window.fetch, {
      apply(fetch, that, args) {
        const result = fetch.apply(that, args);
        result.then((response) => {
          if (response.ok) timeoutChecker.restart();
        });
        return result;
      }
    });
  }
});
