import $ from 'jquery'
import typeahead from 'typeahead-standalone'

$(document).on('shown.bs.modal', (event) => {
  if (event.target.id === 'modalWindow') {
    const typeaheadElement = event.target.querySelector(
      'input[data-typeahead-url]'
    )
    if (typeaheadElement) {
      typeahead({
        input: typeaheadElement,
        limit: 20,
        minLength: 2,
        highlight: true,
        hint: false,
        templates: {
          suggestion: (data) => {
            return `<strong>${data.firstName} ${data.lastName}</strong> <small>&lt;${data.email}&gt;</small>`
          }
        },
        display: (selectedItem, event) => {
          if (event)
            document
              .querySelectorAll('[data-autocomplete-field]')
              .forEach(
                (element) =>
                  (element.value =
                    selectedItem[element.dataset.autocompleteField])
              )

          return selectedItem.email
        },
        source: {
          remote: {
            url: `${typeaheadElement.dataset.typeaheadUrl}.json?query=%QUERY`,
            wildcard: '%QUERY'
          },
          identifier: 'email',
          transform: (data) => data,
          identity: (item) => item.email
        }
      })
    }
  }
})
