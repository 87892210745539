import { Tooltip } from 'bootstrap';
import Rails from "@rails/ujs";
import $ from 'jquery';

const valueChanged = function(element) {
  let left;
  const oldData = (left = element.data('old-data')) != null ? left : '';
  if (element.val() === oldData) {
    return false;
  } else {
    element.data('old-data', element.val());
    return true;
  }
};

class FilterGroup {
  constructor(filterGroup) {
    this.enableFilterGroupAndSubmitForm = this.enableFilterGroupAndSubmitForm.bind(this);
    this.filterGroup  = filterGroup;
    this.filterPanel  = $('.filter', filterGroup);
    this.filterToggle = $('.filter-toggles a', filterGroup);
    this.filterStatusHiddenField = $('.filter-status-toggle', filterGroup);
    this.form = filterGroup.closest('form');
  }

  addEventListeners() {
    const filterGroup = this;

    filterGroup.filterToggle.on('click', function(e) {
      e.preventDefault();
      filterGroup.togglePanel();
    });

    filterGroup.filterPanel.on('click', '.clear-all-filters', e => filterGroup.reset());

    const inputFields = $('input[type="checkbox"], input[type="radio"], select', filterGroup.filterPanel).not(filterGroup.filterStatusHiddenField).not('.taxonomies-select');
    inputFields.on('change.msc-filter', filterGroup.enableFilterGroupAndSubmitForm);
    $(':input', filterGroup.filterPanel).on('changeDate.msc-filter', filterGroup.enableFilterGroupAndSubmitForm);
    $('input[type="text"], textarea', filterGroup.filterPanel).on('keyup.msc-filter', function() {
      if (valueChanged($(this))) {
        clearTimeout($(this).data('timerid'));
        $(this).data('timerid', setTimeout(filterGroup.enableFilterGroupAndSubmitForm, 1000));
      }
    });
  }

  enable() {
    this.filterStatusHiddenField.prop('checked', true);
    this.filterToggle.addClass('enabled');
  }

  reset() {
    this.resetting = true;

    $('input[type="text"]', this.filterGroup).val('');
    $('select', this.filterGroup).val('').trigger('change');
    $('input:checked', this.filterGroup).prop('checked', false);

    this.filterGroup.trigger('msc-filter:reset');

    this.filterToggle.removeClass('enabled');
    this.form.data('reload', false);
    this.submitFilterForm();

    this.resetting = false;
  }

  togglePanel() {
    if (this.filterPanel.is(':visible')) {
      this.hidePanel();
    } else {
      this.showPanel();
    }
  }

  showPanel() {
    $('[data-toggle="filter-group"]', this.form).not(this.filterGroup).each(function() {
      $(this).data('filter-group').hidePanel();
    });

    $('#filter-backdrop').addClass('active');
    this.filterToggle.addClass('active');
    this.filterPanel.removeClass('d-none');
  }

  hidePanel() {
    $('#filter-backdrop').removeClass('active');
    this.filterToggle.removeClass('active');
    this.filterPanel.addClass('d-none');
  }

  submitFilterForm() {
    this.form.trigger('msc-filter:submit-form');
  }

  enableFilterGroupAndSubmitForm() {
    if (!this.resetting) {
      this.enable();
      this.submitFilterForm();
    }
  }
}

class SearchBox {
  static initClass() {
    this.prototype.minChars = 3;
  }
  constructor(searchbox) {
    this.performSearch = this.performSearch.bind(this);
    this.searchbox = searchbox;

    this.tooltip = new Tooltip(searchbox[0], {
      title: `Please type at least ${this.minChars} characters`,
      trigger: 'manual',
      template: '<div class="tooltip seach-box-tooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
      popperConfig: (config) => {return { ...config, placement: 'bottom-start' } }
    });

    this.form = searchbox.closest('form');
    this.formSubmitted = false;
    this.addEventListeners();
  }

  addEventListeners() {
    this.searchbox.on('keyup.msc-filter', this.performSearch);
  }

  currentCharCount() {
    return this.searchbox.val().length;
  }

  performSearch() {
    if (valueChanged(this.searchbox)) {
      if ((this.currentCharCount() < this.minChars) && (this.currentCharCount() > 0)) {
        this.showCharCountWarning();
      } else if ((this.currentCharCount() === 0) && !this.formSubmitted) {
        this.hideCharCountWarning();
      } else {
        this.hideCharCountWarning();

        clearTimeout(this.timerid);
        this.timerid = setTimeout(() => {
          this.submitFilterForm();
          this.formSubmitted = (this.currentCharCount() > 0);
        }
        , 1000);
      }
    }
  }

  showCharCountWarning() {
    if (!this.tooltip._isShown())
      this.tooltip.show();
  }
  hideCharCountWarning() {
    this.tooltip.hide();
  }

  submitFilterForm() {
    this.form.trigger('msc-filter:submit-form');
  }
}
SearchBox.initClass();

class FilterForm {
  constructor(form) {
    this.submitForm = this.submitForm.bind(this);
    this.getResult = this.getResult.bind(this);
    this.form = form;
    this.reloadFilter = false;
  }

  url() {
    return decodeURI(`${this.form.attr("action")}?${this.form.serialize()}`);
  }

  setupFilterForm() {
    this.form.on('msc-filter:submit-form', this.submitForm);
    addEventListener('popstate', this.getResult);

    $('[data-toggle="filter-group"]', this.form).each(function() {

      let filterGroup = $(this).data('filter-group');
      if (filterGroup == null) {
        $(this).data('filter-group', (filterGroup = new FilterGroup($(this))));
      }

      filterGroup.addEventListeners();
    });

    $('.search-box', this.form).each(function() {
      const searchBox = new SearchBox($(this));
      searchBox.addEventListeners();
      $(this).data('search-box', searchBox);
    });
  }

  submitForm() {
    this.reloadFilter = false;
    history.pushState({}, document.title, this.url());
    this.getResult();
  }

  showLoadingIndicator() {
    $('.hide-for-loading').fadeTo('fast', 0.3);
    $('.ajax-table-loading').removeClass('d-none');
  }
  hideLoadingIndicator() {
    $('.hide-for-loading').fadeTo('fast', 1);
    $('.ajax-table-loading').addClass('d-none');
  }

  getResult() {
    this.showLoadingIndicator();
    const url = new URL(window.location)
    Rails.ajax({
      url: url,
      type: 'GET',
      dataType: "script",
      success: () => {
        this.hideLoadingIndicator();
        this.reloadFilter = true;
      },
      complete: () => Rails.fire(document.body, 'ajax:complete', [])
    });
  }
}

$.fn.mscFilterForm = function() {
  this.each(function() {
    let data = $(this).data('msc-filter-form');
    if (data == null) {
      $(this).data('msc-filter-form', (data = new FilterForm($(this))));
    }
    data.setupFilterForm();
  });
};

$(function() {
  if ($('#filter-form').length > 0) {
    $('#filter-form form').mscFilterForm();

    $('body').append($('<div>').attr('id', 'filter-backdrop'));

    $(document).on('click', '.pagination a, a.sort_link', function(e) {
      e.preventDefault();
      const filterForm = $('#filter-form form').data('msc-filter-form');
      filterForm.reloadFilter = false;
      history.pushState({}, document.title, decodeURI($(this).attr('href')));
      filterForm.getResult();
    });

    $(document).on('click', '#filter-backdrop', function(e) {
      $('.filter-toggles a.active').trigger('click');
      return false;
    });
  }
});