import { templateToElement } from './utils';
import { Modal } from 'bootstrap';

const modalTemplate = (element) => {
  const { title, url } = element.dataset;
  const template = `\
  <div class="modal fade">
  <div class="modal-dialog modal-lg modal-pic">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">${title}</h5>
        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="close"></button>
      </div>
      <div class="modal-body d-flex align-items-center justify-content-center">
        <img src="${url}" class="img-fluid flex-shrink-0">
      </div>
    </div>
  </div>
  </div>\
  `;

  return templateToElement(template);
}

document.body.addEventListener('click', (event) => {
  const path = event.composedPath();
  const element = path.find(element => element.matches && element.matches('[data-toggle="modal-lightbox"]'));

  if (element) {
    const modalElement = modalTemplate(element);

    document.body.append(modalElement);
    new Modal(modalElement).show();

    modalElement.addEventListener('hidden.bs.modal', () => modalElement.remove())
    event.preventDefault()
    event.stopPropagation()
  }
});